import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import PageWrapper from '../components/PageWrapper'
import MetaHeader from '../components/MetaHeader'
import Layout from '../layouts'
import { vars } from '../components/theme'
import { Row, Col } from '../components/Grid'
import Hero from '../components/Hero'
import NeedMoreWomen from '../components/NeedMoreWomen'
import NewsletterForm from '../components/NewsletterForm'
import Waves from '../components/common/WaveSection'
import { Card } from '../components/common/Card'
import Checklist from '../components/common/Checklist'
import QuoteBlock from '../components/common/QuoteBlock'

import imgNeedWomen1 from '../images/svgs/need-more-women.svg'
import imgNeedWomen2 from '../images/svgs/need-more-women2.svg'
import LedwellHeadshot from '../images/people/person-l-ledwell.png'

const NeedMoreWomenImages = styled(({ className }) => (
  <div className={className}>
    <NeedMoreWomen
      largeText="1 in 5"
      smallText="of all angel investors are women"
      src={imgNeedWomen1}
    />
    <NeedMoreWomen
      largeText="7%"
      smallText="of deal-making venture capitalists are women"
      src={imgNeedWomen2}
      inverted
    />
  </div>
))`
  display: grid;
  grid-template: repeat(2, 11rem) / 1fr;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;

  @media (min-width: ${vars.screenSmall}) {
    grid-template: 11rem / repeat(2, 1fr);
  }
`

const NeedMoreWomenText = () => (
  <div>
    <h2>We need more women in early-stage funding</h2>
    <p>
      Female leaders possess the industry knowledge, experience, and capital to
      make great investors. Studies indicate that women bring a holistic
      perspective to investment decisions and are better at matching their
      investments to their goals - yet only 1 in 5 investors are women.
    </p>
    <p>
      Female Funders is changing this.{' '}
      <Link to="/women-in-venture">Read the report here.</Link>
    </p>
  </div>
)

const MembershipCard = styled(Card)`
  text-align: left;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  padding: 2rem 3rem;
`
const Testimonial = styled.div`
  margin-top: 2rem;
`
const WaveSection = styled(Waves)`
  margin-bottom: -3rem;
  text-align: center;
`
const IndexPage = () => (
  <Layout>
    <MetaHeader
      title="Empowering female investors"
      canonicalUrl="https://femalefunders.com"
    />
    <Hero
      title={
        <Fragment>Empowering women to shape the future of funding.</Fragment>
      }
      buttonText="Discover Angel Academy"
      buttonTo="/angel-academy/what-is-angel-academy"
    />

    <section>
      <PageWrapper>
        <Row>
          <Col>
            <h2>What is Female Funders?</h2>
            <p>
              Female Funders offers an investor accelerator program that gives
              corporate and tech leaders the knowledge, access, and network
              needed to become successful early-stage investors.
            </p>
          </Col>
        </Row>
      </PageWrapper>
    </section>

    <section>
      <PageWrapper>
        <MembershipCard>
          <h3 className="mt-0">Benefits</h3>
          <Checklist>
            <li>Develop a deep understanding of the angel investing world</li>
            <li>Join a global network of aspiring and experienced investors</li>
            <li>Receive access to pre-screened investment opportunities</li>
            <li>Pool resources alongside experienced investors</li>
            <li>
              Gain the tools and knowledge to make better-informed decisions
            </li>
            <li>Support female entrepreneurs and founders</li>
          </Checklist>
        </MembershipCard>
      </PageWrapper>
    </section>

    <section>
      <PageWrapper>
        <NeedMoreWomenText />
        <NeedMoreWomenImages />
      </PageWrapper>
    </section>

    <section>
      <PageWrapper>
        <Testimonial>
          <QuoteBlock
            img={LedwellHeadshot}
            quote="Investing can be intimidating. Female Funders did a great job of pulling back the onion layers by providing a variety of tools and resources, making it easier to think about investment"
            author="Lauren Ledwell"
            title="Director, RBC Ventures"
          />
        </Testimonial>
      </PageWrapper>
    </section>

    <WaveSection>
      <PageWrapper>
        <NewsletterForm />
      </PageWrapper>
    </WaveSection>
  </Layout>
)

export default IndexPage
