import React from 'react'
import styled from 'styled-components'
import { colors, vars } from '../components/theme'
import Button from '../components/Button'

import heroImg from '../images/backgrounds/hero.jpg'

const HeroScrollArrow = styled(({ className }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 28"
    preserveAspectRatio="none"
    className={className}>
    <polygon points="676.5,0 600,23.3 523.5,0 0,0 0,28 1200,28 1200,0 " />
  </svg>
))`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: 100;

  polygon {
    fill: ${colors.background};
  }
`

const HeroHeading = styled.h1`
  position: relative;
  z-index: 50;
  margin: 0;
  max-width: 1000px;
  text-align: center;
  color: #fff;
  opacity: 0.9;
  text-align: left;
  line-height: 1.2;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);

  @media (min-width: ${vars.screenMedium}) {
    font-size: 3.5rem;
  }
`
const HeroBG = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: url(${heroImg});
  background-attachment: scroll;
  background-position: 50% 10%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (hover) {
    background-attachment: fixed;
    background-position: 50% ${vars.navHeight};
  }
`
const CTAButton = styled(Button)`
  z-index: 100;
  margin: 1rem 0 2rem;
  opacity: 0.95;
`
const Container = styled.div`
  height: 100%;
  background: #d8c0c5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  padding-bottom: 4rem;
  align-items: center;
  position: relative;

  @media (min-width: ${vars.screenLarge}) {
    padding-bottom: 2rem;
  }
`
const HeroWrapper = styled.div`
  max-width: 940px;
  margin: 0 auto;
  z-index: 1;
`
const Hero = styled(({ className, title, buttonText, buttonTo }) => (
  <div className={className}>
    <Container>
      <HeroWrapper>
        <HeroHeading>{title}</HeroHeading>
        <CTAButton to={buttonTo} large>
          {buttonText}
        </CTAButton>
      </HeroWrapper>

      <HeroBG />
    </Container>
    <HeroScrollArrow />
  </div>
))`
  height: calc(90vh - ${vars.navHeight});
  width: 100vw;
  max-height: 800px;
  padding: 1.5rem 1.5rem 0;
  position: relative;
  overflow: hidden;
  margin: 0 0 -2rem;

  @media screen and (min-width: ${vars.screenSmall}) {
    height: calc(99vh - ${vars.navHeight});
  }
`

export default Hero
