import React from 'react'
import styled from 'styled-components'
import { colors, vars } from '../components/theme'

const LargeText = styled.div`
  font-size: 2.75rem;
  margin-bottom: 1rem;
`
const SmallText = styled.div`
  font-weight: 600;
`

const NMWText = styled(({ className, largeText, smallText }) => (
  <div className={className}>
    <LargeText>{largeText}</LargeText>
    <SmallText>{smallText}</SmallText>
  </div>
))`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NMWImage = styled.img.attrs({
  alt: '',
})`
  padding: 0.5rem;
  height: auto;
  max-height: 7rem;
  width: 80%;
  margin: 0 auto;
  object-fit: contain;
`

const Wrapper = styled.div`
  background: ${props => (props.inverted ? colors.white : colors.primary)};
  color: ${props => (props.inverted ? colors.primary : colors.white)};
  text-shadow: ${props => (props.inverted ? 'none' : vars.textShadow)};
  border: 1px solid ${colors.primary};
  padding: 0.5rem;
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 11rem;
  grid-template-columns: repeat(2, 1fr);
`

const NeedMoreWomenImage = ({ largeText, smallText, src, inverted }) => (
  <Wrapper inverted={inverted}>
    <NMWText largeText={largeText} smallText={smallText} />
    <NMWImage src={src} />
  </Wrapper>
)

export default NeedMoreWomenImage
