import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme'

const waveStyling = `
  width: 100vw;
  height: 40px;
  pointer-events: none;

  svg {
    fill: ${colors.primaryLight};
    height: 100%;
    width: 100%;
  }
`
const Wrapper = styled.div`
  margin-top: -2rem;
`
const WaveStart = styled(({ className }) => (
  <div className={className}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 75"
      preserveAspectRatio="none">
      <path d="M0,34.1V75h1200V1c-125.9,0-242.5,16-365.7,32.9C696.3,52.9,550,73,373,73C181,73,39,50.2,0,34.1z" />
    </svg>
  </div>
))`
  ${waveStyling};
`
const WaveEnd = styled(({ className }) => (
  <div className={className}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 40"
      preserveAspectRatio="none">
      <path d="M0 0V40C0 40 616.5 20.5019 839 20.5019C1061.5 20.5019 1200 40 1200 40V0H0Z" />
    </svg>
  </div>
))`
  ${waveStyling};
`

const WaveContent = styled.div`
  background: ${colors.primaryLight};
  width: 100vw;
  padding: 2.5rem 0;
`

const WaveSection = ({ className, children }) => (
  <Wrapper className={className}>
    <WaveStart />
    <WaveContent>{children}</WaveContent>
    <WaveEnd />
  </Wrapper>
)

export default WaveSection
